@tailwind base;
@tailwind components;
@tailwind utilities;

*, *:before, *:after{
    margin: none;
    padding: none;
}
body{
    margin:0;
    background: 141414;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

header{
    background: url('./images/robotsoutside.gif');
    background-size: cover;
    background-repeat: no-repeat;
    
    }

#introduction{
    
    background: url('./images/laptop.jpg') ;
    background-position:center;
    background-size: cover;
}

#services{
     
    background: url('./images/flow.gif') ;
    background-position:center;
    background-size: cover;

   
}


#about{
     
     background: url('./images/ufo.gif') ;
     background-position:center;
     background-size: cover;
 
    
 }

footer{
    
    background: black url('./images/streetgif.gif');
    background-position:center;
    background-size: cover;
}

